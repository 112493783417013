import React from "react";

import '../styles/index.css';

import Layout from "../components/layout";

const NotFoundPage = () => (
  <Layout titlePage="404">
    <div className="container">
      <img src={'https://webinsider.com.br/wp-content/uploads/2019/01/Erro-404-1-1-1024x645.jpg'} alt="" style={{width: '100%', padding: '60px 0', pointerEvents: 'none'}} />
    </div>
  </Layout>
)

export default NotFoundPage
